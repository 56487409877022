import { useState, useEffect, Fragment } from 'react';
import moment from 'moment';

import { Box, Grid, Typography } from '@mui/material';

import { formatPhone } from '../../utils/formatter';

import publicServices from '../../services/publicServices';

const ProposalViewContacts = ({ id, property, billingPlace, issueDate, installationSchedule, createdBy }) => {
  const [companyData, setCompanyData] = useState();

  const getSettings = async () => {
    try {
      const response = await publicServices.getPublicSettings();

      if (response.status === 200) {
        setCompanyData({
          office: response.data.value.officePhone,
          fax: response.data.value.fax,
          cell: response.data.value.cellPhone,
          email: response.data.value.email,
          contactName: response.data.value.contactName,
          companyName: response.data.value.companyName,
          address: response.data.value.address,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <Box sx={{ mt: 5 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography variant="body2" sx={{ minWidth: 34, fontSize: 12 }}>
              From
            </Typography>

            <Box sx={{ borderLeft: '1px solid #000', paddingLeft: 1 }}>
              <Typography sx={{ fontSize: 12 }}>
                <strong>{companyData?.contactName}</strong>
              </Typography>
              <Typography sx={{ fontSize: 12 }}>{companyData?.companyName}</Typography>
              <Typography sx={{ fontSize: 12 }}>{companyData?.address}</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} md={4}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <Typography sx={{ fontSize: 12 }}>Office:</Typography>
              <Typography sx={{ fontSize: 12 }}>Fax:</Typography>
              <Typography sx={{ fontSize: 12 }}>Cell:</Typography>
              <Typography sx={{ fontSize: 12 }}>Email:</Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 12 }}>{formatPhone(companyData?.office)}</Typography>
              <Typography sx={{ fontSize: 12 }}>{formatPhone(companyData?.fax)}</Typography>
              <Typography sx={{ fontSize: 12 }}>{formatPhone(companyData?.cell)}</Typography>
              <Typography sx={{ fontSize: 12 }}>{companyData?.email}</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} md={4}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Typography sx={{ maxWidth: '35%', width: '100%', fontSize: 12 }}>
              <strong>Proposal ID:</strong>
            </Typography>
            <Typography sx={{ fontSize: 12 }}>
              <strong>{id}</strong>
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Typography sx={{ maxWidth: '35%', width: '100%', fontSize: 12 }}>
              <strong>Issue Date:</strong>
            </Typography>
            <Typography sx={{ fontSize: 12 }}>{moment(issueDate).format('MM/DD/YYYY')}</Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Typography sx={{ maxWidth: '35%', width: '100%', fontSize: 12 }}>
              <strong>Billing Address:</strong>
            </Typography>
            <Typography sx={{ fontSize: 12 }}>
              {property.address === billingPlace?.label ? 'Same' : billingPlace?.label}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Typography sx={{ maxWidth: '35%', width: '100%', fontSize: 12 }}>
              <strong>Install Date:</strong>
            </Typography>
            <Typography sx={{ fontSize: 12 }}>
              {installationSchedule.map((el) => (
                <Fragment key={el.id}>
                  <span>
                    {moment(el.date).format('MM/DD/YYYY')} {el.installationTime}
                  </span>
                  <br />
                </Fragment>
              ))}
            </Typography>
          </Box>
        </Grid>

        {property.clients.slice(0, 2).map((client, index) => (
          <Grid item xs={6} md={4} key={client.id}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {index === 0 && <Typography sx={{ minWidth: 34, fontSize: 12 }}>To</Typography>}

              <Box
                sx={{
                  borderLeft: index === 0 && '1px solid #000',
                  paddingLeft: index === 0 && 1,
                  paddingRight: index === 0 && { md: 1, xs: 0 },
                }}
              >
                <Typography sx={{ fontSize: 12 }}>
                  <strong>{client.client.fullName}</strong>
                </Typography>
                {client.relation && <Typography sx={{ fontSize: 12 }}>{client.relation}</Typography>}
                <Typography sx={{ fontSize: 12 }}>{property.address}</Typography>
                <Typography sx={{ fontSize: 12 }}>{formatPhone(client.client.phoneNumber)}</Typography>
                <Typography sx={{ fontSize: 12 }}>{client.client.email}</Typography>
              </Box>
            </Box>
          </Grid>
        ))}

        {/* OLD VERSION BELOW */}

        {/* {companyData && (
          <Grid item xs={6} md={4}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Typography variant="body2" sx={{ minWidth: 34, fontSize: 12 }}>
                From
              </Typography>

              <Box sx={{ borderLeft: '1px solid #000', paddingLeft: 1 }}>
                <Typography variant="body2">
                  <strong>{companyData?.contactName}</strong>
                </Typography>
                <Typography sx={{ fontSize: 12 }}>{companyData?.companyName}</Typography>
                <Typography sx={{ fontSize: 12 }}>{companyData?.address}</Typography>
              </Box>
            </Box>
          </Grid>
        )} */}

        {/* {companyData && (
          <Grid item xs={6} md={4}>
            <Box sx={{ display: 'flex', gap: 3 }}>
              <Box>
                <Typography sx={font_size}>Office:</Typography>
                <Typography sx={font_size}>Fax:</Typography>
                <Typography sx={font_size}>Cell:</Typography>
                <Typography sx={font_size}>Email:</Typography>
              </Box>
              <Box>
                <Typography sx={font_size}>{formatPhone(companyData?.office)}</Typography>
                <Typography sx={font_size}>{formatPhone(companyData?.fax)}</Typography>
                <Typography sx={font_size}>{formatPhone(companyData?.cell)}</Typography>
                <Typography sx={font_size}>{companyData?.email}</Typography>
              </Box>
            </Box>
          </Grid>
        )} */}

        {/* <Grid item xs={12} md={4} sx={{ order: { xs: 3, md: 'unset' } }}>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', gap: { md: 3, xs: 0 } }}>
                <Typography sx={{ fontSize: 12, width: { md: '30%', xs: '40%' } }}>
                  <strong>Proposal ID:</strong>
                </Typography>

                <Typography sx={proposal_id_ellipsis}>
                  <strong>{id}</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', gap: { md: 3, xs: 0 } }}>
                <Typography sx={{ fontSize: 12, width: { md: '30%', xs: '40%' } }}>Issue Date:</Typography>
                <Typography sx={{ fontSize: 12 }}>{moment(issueDate).format('MM/DD/YYYY')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', gap: 3 }}>
                <Typography sx={{ whiteSpace: 'nowrap', fontSize: 12, width: { md: '30%', xs: '40%' } }}>
                  <strong>Billing Address</strong>
                </Typography>
                <Typography sx={{ fontSize: 12 }}>
                  {property.address === billingPlace?.label ? 'Same' : billingPlace?.label}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', gap: { md: 3, xs: 0 } }}>
                <Typography sx={{ whiteSpace: 'nowrap', fontSize: 12, width: { md: '30%', xs: '40%' } }}>
                  <strong>Install Date</strong>
                </Typography>

                <Typography sx={{ fontSize: 12 }}>
                  {installationSchedule.map((el) => (
                    <Fragment key={el.id}>
                      <span>
                        {moment(el.date).format('MM/DD/YYYY')} {el.installationTime}
                      </span>
                      <br />
                    </Fragment>
                  ))}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid> */}

        {/* <Grid item sx={{ order: { xs: 2, md: 'unset' } }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography sx={{ minWidth: 34, fontSize: 12 }}>To</Typography>

            <Grid container spacing={2}>
              {property.clients.slice(0, 2).map((client) => (
                <Grid item xs={4}>
                  <Box
                    sx={{ borderLeft: '1px solid #000', paddingLeft: 1, paddingRight: { md: 1, xs: 0 } }}
                    key={client.id}
                  >
                    <Typography variant="body2">
                      <strong>{client.client.fullName}</strong>
                    </Typography>
                    {client.relation && (
                      <Typography sx={{ fontSize: { xs: 12, md: 14 } }}>{client.relation}</Typography>
                    )}
                    <Typography sx={{ fontSize: { xs: 12, md: 14 } }}>{property.address}</Typography>
                    <Typography sx={{ fontSize: { xs: 12, md: 14 } }}>
                      {formatPhone(client.client.phoneNumber)}
                    </Typography>
                    <Typography sx={{ fontSize: { xs: 12, md: 14 } }}>{client.client.email}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default ProposalViewContacts;
